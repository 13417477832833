import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      tabIsHidden: true,
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  toggleTabs = (e) => {
    this.setState({ tabIsVisible: !this.state.tabIsVisible })
  }

  render() {
    return (
      <Layout>
        <section className="section section--gradient pb-6">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-12-mobile is-10-desktop is-offset-1-desktop">
                <div className="section has-text-centered">
                  <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
                    Contact Us
                  </h2>
                  <p className="has-text-grey">
                    ยินดีให้บริการทุกวัน
                  </p>
                </div>
                <div className="section">

                  <div className="tabs is-toggle is-fullwidth is-large mb-6">
                    <ul>
                      <li className={ !this.state.tabIsVisible ? 'is-active' : null }>
                        <a onClick={this.toggleTabs.bind(this)}>
                          <span className="has-text-weight-bold">กรุงเทพๆ</span>
                        </a>
                      </li>
                      <li className={ this.state.tabIsVisible ? 'is-active' : null }>
                        <a onClick={this.toggleTabs.bind(this)}>
                          <span className="has-text-weight-bold">สัตหีบ</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="columns is-desktop">
                    <div className="column is-6-desktop">
                      {!this.state.tabIsVisible && <TabOne />}
                      {this.state.tabIsVisible && <TabTwo />}
                    </div>
                    <div className="column is-hidden-desktop">
                      <hr />
                    </div>
                    <div className="column is-6-desktop">

                      <form
                        name="contact"
                        method="post"
                        action="/contact/thanks/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={this.handleSubmit}
                      >
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="contact" />
                        <div hidden>
                          <label>
                            Don’t fill this out:{' '}
                            <input name="bot-field" onChange={this.handleChange} />
                          </label>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={'name'}>
                            ชื่อ
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'text'}
                              name={'name'}
                              onChange={this.handleChange}
                              id={'name'}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={'email'}>
                            อีเมล
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'email'}
                              name={'email'}
                              onChange={this.handleChange}
                              id={'email'}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={'tel'}>
                            เบอร์โทร
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'tel'}
                              name={'tel'}
                              onChange={this.handleChange}
                              id={'tel'}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={'message'}>
                            ข้อความ
                          </label>
                          <div className="control">
                            <textarea
                              className="textarea"
                              name={'message'}
                              onChange={this.handleChange}
                              id={'message'}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <button className="button has-text-weight-bold is-fullwidth" type="submit">
                            ส่งข้อความ
                          </button>
                        </div>
                      </form>


                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

const TabOne = () => (
  <div>
    <p className="has-text-weight-bold mb-3">
      ที่อยู่
    </p>
    <p>Starlight Property สาขากรุงเทพฯ</p>
    <p>456/226 ถ.เพชรบุรี</p>
    <p>แขวงถนนเพชบุรี</p>
    <p>ราชเทวี</p>
    <p className="mb-5">กรุงเทพฯ 10400</p>
    <p className="mb-1">
      <span className="has-text-weight-bold is-inline-block has-width-50">โทร: </span>
      <a href="tel:+0966566000">096 656 6000</a>
    </p>
    <p className="mb-1">
      <span className="has-text-weight-bold is-inline-block has-width-50">อีเมล: </span>
      <a href="mailto:sales@starlightpropertygroup.com">sales@starlightpropertygroup.com</a>
    </p>
    <p className="mb-6">
      <span className="has-text-weight-bold is-inline-block has-width-50">LINE: </span>
      <a href="https://line.me/ti/p/~@starlightproperty">@starlightproperty</a> (มี @ นำหน้า)
    </p>
    <a href="https://goo.gl/maps/DBiproWGXZFbrpbZ9" target="_blank" className="button has-text-weight-bold">
      ดูเส้นทางการเดินทาง
    </a>
  </div>
)

const TabTwo = () => (
  <div>
    <p className="has-text-weight-bold mb-3">
      ที่อยู่
    </p>
    <p>Starlight Property สาขาสัตหีบ</p>
    <p>12/23 ม.6 ซอยสุขุมวิท 99 (ซอยเขาหมอน)</p>
    <p>ต.สัตหีบ</p>
    <p>อ.สัตหีบ</p>
    <p className="mb-5">ชลบุรี 20180</p>
    <p className="mb-1">
      <span className="has-text-weight-bold is-inline-block has-width-50">โทร: </span>
      <a href="tel:+66654387955">065 438 7955</a>
    </p>
    <p className="mb-1">
      <span className="has-text-weight-bold is-inline-block has-width-50">อีเมล: </span>
      <a href="mailto:sales@starlightpropertygroup.com">sales@starlightpropertygroup.com</a>
    </p>
    <p className="mb-6">
      <span className="has-text-weight-bold is-inline-block has-width-50">LINE: </span>
      <a href="https://line.me/ti/p/~@starlightproperty">@starlightproperty</a> (มี @ นำหน้า)
    </p>
    <a href="https://goo.gl/maps/EMLuiMYHAKd85uhT6" target="_blank" className="button has-text-weight-bold">
      ดูเส้นทางการเดินทาง
    </a>
  </div>
)
